<template>
  <v-dialog v-model="imageViewer.flag" fullscreen>
    <v-card>
      <v-card-title>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="1" class="pa-0 text-right">
            <v-icon size="30" color="white" @click="closeModal"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="fill-height">
        <v-row justify="space-around">
          <v-col
            class="d-flex justify-center"
            v-if="imageViewer.type === 'image'"
            cols="10"
            sm="10"
          >
            <img class="alignVertically Image" :src="imageViewer.src" alt="" />
          </v-col>
          <v-col
            cols="12"
            sm="10"
            v-else
            class="d-flex justify-space-around fill-height"
          >
            <video
              ref="video"
              controls
              preload="auto"
              class="alignVertically video"
              autoplay
              playsinline
              style="pointer-events: fill"
            >
              <source :src="imageViewer.src" type="video/mp4" />
              <source :src="imageViewer.src" type="video/mov" />
              Your browser does not support the video format
            </video>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "FullScreenImageiewer",
  props: ["imageViewer"],
  computed: {
    Time() {
      return this.$refs.video.currentTime;
    },
  },
  watch: {},
  methods: {
    closeModal() {
      this.imageViewer.flag = false;
      this.imageViewer.src = "";
      if (this.imageViewer.type === "video") this.$refs.video.pause();
      // console.log(this.$refs.video.currentTime, "video");
      // console.log(document.querySelector("video"), "Videeeeeee");
    },
    // watchvideo() {
    //   let time = Math.round(this.$refs.video.currentTime/this.$refs.video.duration*100)
    //   // console.log(time,this.$refs.video.currentTime,this.$refs.video.duration,"video")
    //   if(this.$route.matched[0].path.substring(1)==='parent'){
    //     if(time ==80){
    //       console.log(time,"80% has done")
    //       this.$root.$refs.videoSection.submitCompleted()
    //     }
    //   }
    // },
  },
};
</script>
<style scoped>
.alignVertically {
  margin: 391px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.Image {
  max-width: 800px;
  max-height: 550px;
}
.video {
  width: 65%;
}
.v-card {
  background-color: rgba(41, 48, 54, 0.82);
}
/*video::-webkit-media-controls-timeline {
    display: none;
    
}*/
@media only screen and (max-width: 600px) {
  .Image {
    width: 100%;
  }
  .video {
    width: 100%;
  }
}
</style>
